import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import MediaPageHeaderDesign from '../../../assets/images/mediapage-design-header.inline.svg';
import { IconBTAlert, IconBTDocument } from '../../../Atoms/Icons/Icons';
import ArticleHighlight from '../../../components/ArticleHighlight/ArticleHighlight';
import ArticleSingle from '../../../components/ArticleSingle/ArticleSingle';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Dropdown from '../../../Molecules/Dropdown/Dropdown';
import PageInnerNav from '../../../components/PageInnerNav/PageInnerNav';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import AlertBlock from '../../../Molecules/AlertBlock/AlertBlock';
import { Button } from '../../../Molecules/Button/Button';
import FiltersTag from '../../../Molecules/FiltersTag/FiltersTag';
import SearchFilter from '../../../Molecules/SearchFilter/SearchFilter';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';
import { isSentenceContainsKeywords } from '../../../Utils/SearchEngine';
import { getBreadCrumb } from '../../../Utils/Utils';
import useMedias from '../../../hooks/useMedias';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import './styles.scss';

const classNames = require('classnames');

const MediaPage = ({ data, pageContext }) => {
  const intl = useIntl();
  const { processUrl, getImage, getDocument } = useMedias();

  const articles = data.articles.edges.filter((r) => { return !r.node.field_exclude_display; }).map((a) => a.node);
  const mediaPageData = data.media;
  const imageArray = data.allImages.edges;
  const showMoreVal = 5;
  const documentsArray = data.allDocuments.edges;
  const allMenu = data.allMenu.edges;
  const publicationCat = data.publicationCat?.edges;
  const mediaCat = data.mediaCat?.edges;
  const breadCrumb = getBreadCrumb(
    allMenu,
    mediaPageData.path?.alias !== null &&
      mediaPageData.path?.alias !== undefined
      ? mediaPageData.path?.alias
      : ''
  );

  const [highlightedArticle, setHighlightedArticle] = useState();
  const [filteredArticle, setFilteredArticle] = useState(articles);
  const [filter_category, setFilterCategory] = useState();
  const [filter_year_combo, setFilteryearCombo] = useState();
  const [filter_search, setFilterSearch] = useState();
  const [filter_tag, setFilterTag] = useState();
  const [currentNav, setCurrentNav] = useState();
  const [showItem, setShowItem] = useState(showMoreVal);
  const [yearListCombo, setYearListCombo] = useState([]);
  const [paramYear, setParamYear] = useState();
  const [hasChangeCategory, setHasChangeCategory] = useState(false);
  const [filters, setFilter] = useState([]);
  const [filterMedia, setFilterMedia] = useState([]);
  const metaTags = mediaPageData.metatag;
  const [dropdownSrOnlymsg, setDropdownSrOnlyMsg] = useState()
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  let myYearCombo = [];

  const handleFilterCategoryChange = (category) => {
    setFilterCategory(category);
    setCurrentNav(category);
    setShowItem(showMoreVal);

    switch (category.id) {
      case 'news':
        setFilterSearch();
        break;
      case 'press-release':
        setFilterTag();
        break;
      case 'publications':
        setFilterSearch();
        break;
      default:
        setFilteryearCombo();
        setFilterSearch();
        setFilterTag();
        break;
    }

    !hasChangeCategory ? setHasChangeCategory(true) : setParamYear(null);
  };

  const handleFilterYearChange = (year) => {
    setFilterSearch();
    setFilteryearCombo(year);
  };

  const handleFilterSearchChange = (search) => {
    setFilterSearch(search);
  };

  const handlefilterTagChange = (tag) => {
    setFilterTag(tag);
  };

  const onClickShowMore = () => {
    if (showItem < filteredArticle?.length) {
      setShowItem(showItem + showMoreVal);
    }
  };

  const setdropDownList = () => {
    let i = 0;
    const articleCatSelected = articles.filter(
      (a) =>
        a.relationships.field_category?.drupal_internal__tid ===
        filter_category?.drupalTID
    );
    articleCatSelected?.forEach((article) => {
      if (myYearCombo.indexOf(article?.fields?.publicationYear) < 0) {
        myYearCombo.push(article?.fields?.publicationYear);
      }
    });
    setYearListCombo(myYearCombo);
  };

  const getParamFromUrl = () => {
    let queries = location.search.substring(1).split('&'),
      processed = {};
    for (let query of queries) {
      let [name, value] = query.split('=');
      processed[decodeURIComponent(name)] = value
        ? decodeURIComponent(value)
        : '';
    }
    return processed;
  };

  const mediaCatInit = () => {
    let mediaCatArr = [];

    mediaCat.forEach(
      function (e) {
        mediaCatArr.push(
          {
            drupalTID: e.node?.drupal_internal__tid,
            name: e.node?.name,
          }
        )
      }
    )

    return mediaCatArr;
  }

  const publicationCatInit = () => {
    let pubCatArr = [];

    publicationCat.forEach(
      function (e) {
        pubCatArr.push(
          {
            id: 'pub_cat_' + e.node?.drupal_internal__tid,
            drupalTID: e.node?.drupal_internal__tid,
            name: e.node?.name,
          }
        )
      }
    )

    return pubCatArr;
  }

  const handleUrlDirectOpen = (e) => {
    e?.preventDefault;
    let currentUrl = location.pathname;
    if (e) window?.open(`/${intl.locale}/media/${e}`, '_self');
    if (getParamFromUrl().openYear) setParamYear(getParamFromUrl().openYear);

    let tabObj = {
      id: 'all',
      drupalTID: 0,
      name: 'tous'
    };

    mediaCat.forEach((item) => {
      if (currentUrl.includes(intl.formatMessage({ id: 'media.url.news' }))) {
        tabObj = {
          id: 'news',
          drupalTID: item?.node?.drupal_internal__tid,
          name: 'actualites'
        };
      }
      else if (currentUrl.includes(intl.formatMessage({ id: 'media.url.press' }))) {
        tabObj = {
          id: 'press-release',
          drupalTID: item?.node?.drupal_internal__tid,
          name: 'communiques'
        };
      }
      else if (currentUrl.includes('publications')) {
        tabObj = {
          id: 'publications',
          drupalTID: item?.node?.drupal_internal__tid,
          name: 'publications'
        };
      }
    });

    handleFilterCategoryChange(tabObj);
    setCurrentNav(tabObj);
  };

  const navigationInit = () => {
    let navArr = [
      {
        id: 'all',
        drupalTID: 0,
        name: intl.formatMessage({ id: 'media.categories.all' }),
        url: `/${intl.locale}/media`,
      }
    ];

    mediaCat.forEach((e) => {
      if (e.node?.drupal_internal__tid === 6) {
        navArr.push({
          id: 'news',
          drupalTID: e.node?.drupal_internal__tid,
          name: intl.formatMessage({ id: 'media.categories.actuality' }),
          url: `/${intl.locale}/media/${intl.formatMessage({ id: 'media.url.news' })}`,
        });
      } else if (e.node?.drupal_internal__tid === 7) {
        navArr.push({
          id: 'press-release',
          drupalTID: e.node?.drupal_internal__tid,
          name: intl.formatMessage({ id: 'media.categories.press' }),
          url: `/${intl.locale}/media/${intl.formatMessage({ id: 'media.url.press' })}`,
        });
      } else if (e.node?.drupal_internal__tid === 8) {
        navArr.push({
          id: 'publications',
          drupalTID: e.node?.drupal_internal__tid,
          name: intl.formatMessage({ id: 'media.categories.publications' }),
          url: `/${intl.locale}/media/publications`,
        });
      }
    });

    return navArr.length > 1 ? navArr : [];
  };

  useEffect(() => {
    setdropDownList();
    setFilteryearCombo(
      paramYear
        ? myYearCombo.filter((a) => a === parseInt(paramYear))[0]
        : myYearCombo[0]
    );
  }, [filter_category]);

  useEffect(() => {
    handleUrlDirectOpen();
    setFilter(publicationCatInit());
    setFilterMedia(navigationInit());
  }, []);

  useEffect(() => {
    if (myYearCombo.length > 0) {
      setFilteryearCombo(myYearCombo[0]);
    }
  }, [yearListCombo])

  // Effect: Filter
  useEffect(() => {
    const processfilter = () => {
      let updatedArticles = articles;
      if (filter_category?.drupalTID > 0) {
        updatedArticles = updatedArticles.filter(
          (a) =>
            a.relationships.field_category?.drupal_internal__tid ===
            filter_category?.drupalTID
        );
      }
      if (
        filter_year_combo &&
        filter_category?.drupalTID === 7 &&
        filter_search === undefined
      ) {
        updatedArticles = updatedArticles.filter(
          (a) => a.fields.publicationYear == filter_year_combo
        ); // Actualy compare string between int!
      }
      if (filter_search) {
        updatedArticles = updatedArticles.filter((a) =>
          isSentenceContainsKeywords(filter_search, [
            a?.title,
            a?.body?.summary != null ? a?.body?.summary : '',
          ])
        ); // only lookup in title & summary
      }
      if (filter_tag) {
        updatedArticles = updatedArticles.filter((a) =>
          a.relationships.field_tags.some(
            (t) => t.drupal_internal__tid === filter_tag.drupalTID
          )
        );
      }

      setHighlightedArticle(updatedArticles.find((a) => a));
      setFilteredArticle(updatedArticles);

      setDropdownSrOnlyMsg('')

      setTimeout(() => {

        setDropdownSrOnlyMsg(`${intl.formatMessage({ id: 'media.dropdown_sr_only' })}, ${updatedArticles.length} ${intl.formatMessage({ id: 'media.filter_sr_only' })}`)

      }, 1000);
    };

    processfilter();
  }, [filter_category?.drupalTID, filter_search, filter_tag, filter_year_combo]);

  return (
    <Layout imagesArray={imageArray}>
      <Seo
        title={mediaPageData?.field_metatag?.title ? mediaPageData?.field_metatag?.title : metaTitle}
        description={mediaPageData?.field_metatag?.description ? mediaPageData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'page_media')}>
        <MediaPageHeaderDesign className="media_top_design" />
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              parentPage: {
                title: breadCrumb?.parent?.name,
                url: breadCrumb?.parent?.link,
              },
              currentPage: {
                title: breadCrumb?.current?.name,
                url: breadCrumb?.current?.link,
              },
              locale: mediaPageData.langcode,
            }}
          />
          <TitlePage color="color_dark_bleu" title={mediaPageData?.title} />
          <PageInnerNav
            nav={filterMedia}
            customClass={
              filter_category?.id === 'press-release' ||
                filter_category?.id === 'publications'
                ? 'little_marge'
                : ''
            }
            onFilterChange={handleFilterCategoryChange}
            currentNavFromParent={currentNav}
          />
          {(filter_category?.id === 'press-release' ||
            filter_category?.id === 'publications') && (
              <div className="filters">
                {filter_category?.id === 'press-release' && (
                  <div className="flex_line">
                    {
                      filter_year_combo &&
                      <Combobox
                        defaultValue={filter_year_combo}
                        data={yearListCombo}
                        onChange={handleFilterYearChange}
                      />
                    }
                    <SearchFilter
                      className="search-filter"
                      placeholder={intl.formatMessage({
                        id: 'search.cta.placeholder',
                      })}
                      splitChar=" "
                      onFilterChange={handleFilterSearchChange}
                    />
                    <span role="status" className="sr-only">
                      {
                        filteredArticle?.length > 0 ?
                          // <><FormattedMessage id="media.dropdown_sr_only" />, {filteredArticle?.length} <FormattedMessage id="media.filter_sr_only" /></>
                          dropdownSrOnlymsg
                          :
                          <FormattedMessage id="search.zeroResult" />
                      }
                    </span>
                  </div>
                )}
                {filter_category?.id === 'publications' && (
                  <FiltersTag
                    tags={filters}
                    currentTag={filter_tag}
                    customClass="only_mobile_tablet"
                    onFilterChange={handlefilterTagChange}
                  />
                )}
              </div>
            )}
          <div className="articles_container">
            <div className="list_articles" aria-live="polite">
              {filteredArticle && filteredArticle?.length === 0 && (
                <div className="zero_search_result">
                  <p>{intl.formatMessage({ id: 'search.zeroResult' })}</p>
                </div>
              )}
              {highlightedArticle && filter_category?.id != 'publications' && (
                <ArticleHighlight
                  title={highlightedArticle.title}
                  reading_time={highlightedArticle.fields.readingTime.format}
                  reading_time_minutes={highlightedArticle.fields.readingTime.minutes}
                  reading_time_seconds={highlightedArticle.fields.readingTime.seconds}
                  linkTo={
                    highlightedArticle?.relationships?.field_category
                      ?.drupal_internal__tid ===
                      8 /*&& currentNav?.id === 'publications'*/
                      ? processUrl(
                        getDocument(
                          documentsArray,
                          highlightedArticle.relationships?.field_media_pdf
                            ?.drupal_internal__mid
                        )?.uri?.url
                      )
                      : highlightedArticle?.fields?.slug
                  }
                  category={
                    highlightedArticle?.relationships?.field_category
                      ?.drupal_internal__tid
                  }
                  image={getImage(
                    imageArray,
                    highlightedArticle.relationships?.field_image
                      ?.drupal_internal__mid
                  )}
                  altImage={highlightedArticle.relationships?.field_image?.field_alt}
                  page_category={currentNav.name}
                  socialShareIcons={pageContext['config_data_' + intl.locale]}
                />
              )}
              <ul>
                {filteredArticle &&
                  filteredArticle.map((article, i) => (
                    <li
                      key={i}
                      data-category={article?.relationships?.field_category?.drupal_internal__tid}
                      className={classNames(
                        i < showItem ? 'show-item' : 'hide-item'
                      )}
                    >
                      {i < showItem && (
                        <ArticleSingle
                          title={article.title}
                          reading_time={article.fields.readingTime.format}
                          reading_time_minutes={article.fields.readingTime.minutes}
                          reading_time_seconds={article.fields.readingTime.seconds}
                          summary={article.body?.summary}
                          linkTo={
                            article?.relationships?.field_category
                              ?.drupal_internal__tid ===
                              8 /*&& currentNav?.id === 'publications'*/
                              ? processUrl(
                                getDocument(
                                  documentsArray,
                                  article.relationships?.field_media_pdf
                                    ?.drupal_internal__mid
                                )?.uri?.url
                              )
                              : article?.fields?.slug
                          }
                          xhtml={
                            article?.relationships?.field_category
                              ?.drupal_internal__tid ===
                              8
                              ? processUrl(
                                getDocument(
                                  documentsArray,
                                  article.relationships?.field_media_xhtml
                                    ?.drupal_internal__mid
                                )?.uri?.url
                              )
                              : article?.fields?.slug
                          }
                          image={getImage(
                            imageArray,
                            article.relationships?.field_image
                              ?.drupal_internal__mid
                          )}
                          altImage={article.relationships?.field_image?.field_alt}
                          category={filterMedia && filterMedia.length > 0 ?
                            filterMedia.filter(
                              (a) =>
                                a.drupalTID ===
                                article.relationships.field_category
                                  ?.drupal_internal__tid
                            )[0]
                            : ''}
                          currentNav={currentNav?.id}
                          socialShareIcons={pageContext['config_data_' + intl.locale]}
                        />
                      )}
                    </li>
                  ))}
              </ul>
              <div className="btn_container_desktop">
                <span id="int2_desktop" className="sr-only">{intl.formatMessage({ id: 'common.showMore_sr_only' })}</span>
                {
                  currentNav &&
                  <Button
                    label={intl.formatMessage({ id: 'common.showMore' })}
                    icon="plus"
                    onClick={onClickShowMore}
                    customClass={
                      showItem >= filteredArticle?.length ? 'hide' : ''
                    }
                    aria-label={
                      intl.formatMessage({ id: `common.showMore_${currentNav?.id?.replace('-', '_')}` })
                    }
                    aria-describedby="int2_desktop"
                  />
                }

              </div>
            </div>
            <div className="btn_container_mobile">
              <span id="int2_mobile" className="sr-only">{intl.formatMessage({ id: 'common.showMore_sr_only' })}</span>
              <Button
                label={intl.formatMessage({ id: 'common.showMore' })}
                icon="plus"
                onClick={onClickShowMore}
                customClass={showItem >= filteredArticle?.length ? 'hide' : ''}
                aria-label={intl.formatMessage({ id: 'common.showMore' })}
                aria-describedby="int2_mobile"
              />
            </div>
            <div className="col_2_items">
              {filter_category?.id === 'publications' && (
                <FiltersTag
                  tags={filters}
                  currentTag={filter_tag}
                  customClass="only_desktop"
                  onFilterChange={handlefilterTagChange}
                />
              )}

              {mediaPageData.relationships.field_blocs.map((block, i) => {
                let ctaType = block?.field_type_bloc_contact;
                switch (block.__typename) {
                  case 'block_content__block_contact':
                    return (
                      <div key={i} className="cta_block">
                        {((ctaType === 'alert' && currentNav?.drupalTID != 7) ||
                          (ctaType === 'document' &&
                            currentNav?.drupalTID != 8) ||
                          (ctaType === 'actualites' &&
                            currentNav?.drupalTID != 6 &&
                            currentNav?.drupalTID != 0)) && (
                            <AlertBlock
                              link="#"
                              label={block.body?.processed}
                              icon={
                                block?.field_type_bloc_contact === 'document' ? (
                                  <IconBTDocument />
                                ) : (
                                  <IconBTAlert />
                                )
                              }
                              directopen={
                                block?.field_type_bloc_contact === 'alert'
                                  ? intl.formatMessage({ id: 'media.url.press' })
                                  : block?.field_type_bloc_contact === 'document'
                                    ? 'publications'
                                    : intl.formatMessage({ id: 'media.url.news' })
                              }
                              onClick={handleUrlDirectOpen}
                            />
                          )}
                      </div>
                    );
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

MediaPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query MediaTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    media: nodePage(langcode: { eq: $locale }, path: { alias: { eq: $slug } }) {
      langcode
      path {
        alias
        langcode
      }
      field_metatag {
        description
        title
      }
      metatag {
        attributes {
          content
          name
        }
      }
      title
      relationships {
        field_blocs {
          __typename
          ... on Node {
            ... on block_content__block_contact {
              field_type_bloc_contact
              body {
                processed
              }
            }
          }
        }
      }
    }
    articles: allNodeArticle(
      filter: { langcode: { eq: $locale } }
      sort: { order: DESC, fields: created }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
          created
          title
          field_exclude_display
          relationships {
            field_category {
              drupal_internal__tid
            }
            field_tags {
              drupal_internal__tid
            }
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
              field_alt
            }
            field_media_pdf {
              drupal_internal__mid
            }
            field_media_xhtml {
              drupal_internal__mid
            }
          }

          body {
            summary
          }
          fields {
            slug
            publicationYear
            readingTime {
              format
              minutes
              seconds
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
    publicationCat : allTaxonomyTermCategorieMedias(filter: {langcode: { eq: $locale }}) {
      edges {
        node {
          name
          drupal_internal__tid
          
        }
      }
    }
    mediaCat :  allTaxonomyTermCategory(filter: {langcode: { eq: $locale }}) {
      edges {
        node {
          name
          drupal_internal__tid
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default MediaPage;
